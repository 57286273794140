<template>
  <div id="a-time-ended-container"></div>
  <div id="a-test-container">
    <div id="a-test-title">
      <Menubar />
    </div>
    <Timer
      :isActive="isTimerInitialized"
      :staticTimer="selectedTest.time"
      @onTimeEnded="onTimeEnded"
    />
    <div id="a-test-content">
      <QuestionCard
        v-for="(question, index) in selectedTest.questionList"
        :key="index"
        :question="question"
        :index="index"
      />
      <div id="a-form-buttons">
        <primary-button
          :title="'Enviar'"
          :isValid="
            answeredQuestions.length == selectedTest.questionList.length
          "
          :isLoading="isLoading"
          @onClick="storeAnswers"
        />
        <Button label="Regresar" class="p-button-danger" />
      </div>
    </div>
  </div>

  <Dialog
    header=""
    v-model:visible="displayDialog"
    :modal="true"
    :closable="false"
  >
    <div v-html="dialogContent"></div>
    <div>
      <primary-button
        :title="'Iniciar'"
        :isValid="true"
        :isLoading="false"
        @onClick="
          displayDialog = false;
          isTimerInitialized = true;
        "
      />
    </div>
  </Dialog>
  <ConfirmDialog />
</template>

<script>
import Menubar from "@/components/navigation/Menubar.vue";
import Timer from "@/components/timer/Timer.vue";
import QuestionCard from "@/components/test/QuestionCard.vue";
import PrimaryButton from "@/components/widgets/PrimaryButton.vue";
import { mapMutations, mapGetters, mapState } from "vuex";
export default {
  name: "TestPage",
  components: {
    Menubar,
    Timer,
    QuestionCard,
    PrimaryButton,
  },
  data() {
    return {
      isLoading: false,
      displayDialog: true,
      dialogContent: "",
      isTimerInitialized: false,
      isTimeEnded: true,
    };
  },
  computed: {
    ...mapState("auth", ["user"]),
    ...mapState("test", ["selectedTest"]),
    ...mapState("answers", ["answers", "storedAnswers", "hasErrors"]),
    ...mapGetters("answers", ["answeredQuestions"]),
  },
  watch: {
    storedAnswers: {
      handler(value) {
        if (value.length > 0) {
          this.answers.length == value.length ? this.onSuccess() : null;
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.dialogContent = `
      <h2>Debes tener en cuenta: </h2>
      <ul>
        <li>La prueba tiene un tiempo límite, una vez finalizado solo serán procesadas las respuestas almacenadas.</li>
        <li>No olvides guardar cada respuesta.</li>
        <li> si terminas el examen sin guardar las opciones seleccionadas, estas no serán procesadas.</li>
        <li>Si deseas modificar tu selección, no olvides guardar la respuesta con la opción "Actualizar".</li>
        <li>Tienes un número limitado de intentos para esta prueba.</li>
      </ul>
    `;
  },
  methods: {
    ...mapMutations("answers", ["resetAnswers"]),
    onSuccess() {
      this.resetAnswers();
      this.$router.push({ name: "Home" });
    },
    onSuccessFalse() {
      if (this.hasErrors) {
        this.isLoading = false;
      }
    },
    async onTimeEnded() {
      this.isTimeEnded = true;
      await this.$store.dispatch("auth/updateToken");
      this.$store.dispatch("answers/SAVE_ANSWER_LIST");
    },
    async storeAnswers() {
      this.isLoading = true;
      this.$confirm.require({
        message:
          "Recuerde revisar que todas las respuestas hayan sido almacenadas antes de continuar",
        header: "Terminar examen",
        acceptLabel: "Aceptar",
        acceptClass: "p-button-success",
        rejectLabel: "Cancelar",
        accept: async () => {
          //callback to execute when user confirms the action
          await this.$store.dispatch("auth/updateToken");
          this.$store.dispatch("answers/SAVE_ANSWER_LIST");
        },
        reject: () => {
          //callback to execute when user rejects the action
          this.isLoading = false;
        },
      });
    },
  },
};
</script>

<style>
.start-button {
  display: flex;
}

#a-rounded-icon-color-btn {
  margin-right: 10px;
}
#app {
  max-height: 100%;
}
.a-time-ended-container {
  position: absolute;
  z-index: 999999;
  background: rgba(0, 0, 0, 0.377);
  height: 100%;
  width: 100%;
}
</style>

<style scoped>
.a-page-buttons {
  border: none;
  background: none;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  cursor: pointer;
}
.a-page-buttons:disabled,
.a-page-buttons:disabled #a-rounded-icon-btn {
  cursor: not-allowed !important;
}
</style>
