<template>
  <div id="a-general-qcard">
    <div id="a-question-number">
      <span id="a-text">Pregunta</span>
      <span id="a-number" v-if="index < 9">0{{ index + 1 }}</span>
      <span id="a-number" v-else>{{ index + 1 }}</span>
    </div>
    <div id="a-question-card">
      <p>{{ question.name }}</p>

      <div
        class="a-options-container"
        v-if="
          question.questionType.name == 'Selección múltiple. Múltiple respuesta'
        "
      >
        <div
          v-for="(option, index) in question.options"
          :key="index"
          class="p-field-checkbox"
        >
          <Checkbox :value="option.id" v-model="selectedAnswer" />
          <label>{{ option.name }}</label>
        </div>
      </div>
      <!-- ÚNICA RESPUESTA -->
      <div
        class="a-options-container"
        v-if="
          question.questionType.name == 'Selección múltiple. Única respuesta'
        "
      >
        <div
          v-for="(option, index) in question.options"
          :key="index"
          class="p-field-radiobutton"
        >
          <RadioButton :value="option.id" v-model="selectedAnswer" />
          <label>{{ option.name }}</label>
        </div>
      </div>
      <!-- RESPUESTA ABIERTA -->
      <div v-if="question.questionType.name == 'Pregunta abierta'">
        <div class="p-field">
          <Textarea
            :autoResize="false"
            rows="7"
            cols="25"
            placeholder="Escribe tu respuesta."
            v-model="selectedAnswer"
          />
        </div>
      </div>
    </div>

    <div class="a-overlay-question-panel">
      <div :class="classToAdd" @click="saveAnswerOnSelect">
        <span v-if="!saved">Guardar respuesta</span>
        <span v-else>Actualizar respuesta</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
export default {
  name: "QuestionCard",
  props: {
    question: Object,
    index: Number,
  },
  data() {
    return {
      selectedAnswer: null,
      saved: false,
      isLoading: false,
    };
  },
  computed: {
    ...mapState("auth", ["user"]),
    ...mapState("test", ["selectedTest"]),
    ...mapState("answers", ["answers", "answerAttempt"]),
    questionType() {
      return this.question.questionType.name;
    },
    classToAdd() {
      if (this.saved && !this.isLoading) {
        return "a-saved";
      } else if (this.saved && this.isLoading) {
        return "a-saved a-saving";
      } else if (!this.saved && !this.isLoading) {
        return "a-unsaved";
      } else if (!this.saved && this.isLoading) {
        return "a-unsaved a-saving";
      } else {
        return "";
      }
    },
  },
  mounted() {
    this.resetAnswers();
  },
  methods: {
    ...mapMutations("answers", ["storeAnswer", "resetAnswers"]),
    saveAnswerOnSelect() {
      this.isLoading = true;
      let dataToStore = {
        user: this.user.pk,
        answer: "",
        approved: false,
        active: true,
        test: this.selectedTest.id,
        question: this.question.id,
        answerTest: this.answerAttempt.id,
        option: null,
      };
      if (this.questionType == "Selección múltiple. Múltiple respuesta") {
        this.selectedAnswer.forEach((element, index) => {
          let currentData = { ...dataToStore, index: index };
          currentData.option = element;
          if (!this.saved) {
            this.storeAnswer(currentData);
            this.saved = true;
            index++;
          } else {
            this.$store.dispatch("answers/UPDATE_LOCAL_ANSWER", currentData);
          }
        });
      } else if (this.questionType == "Selección múltiple. Única respuesta") {
        dataToStore.answer = "";
        dataToStore.option = this.selectedAnswer;
        dataToStore.isOnlyAnswer = true;

        if (!this.saved) {
          this.storeAnswer(dataToStore);
          this.saved = true;
        } else {
          this.$store.dispatch("answers/UPDATE_LOCAL_ANSWER", dataToStore);
        }
      } else {
        dataToStore.answer = this.selectedAnswer;
        dataToStore.option = null;
        dataToStore.isOnlyAnswer = true;
        if (!this.saved) {
          this.storeAnswer(dataToStore);
          this.saved = true;
        } else {
          this.$store.dispatch("answers/UPDATE_LOCAL_ANSWER", dataToStore);
        }
      }
      console.log(this.answers);
    },
  },
};
</script>

<style scoped>
#a-general-qcard {
  position: relative;
  display: flex;
}
#a-question-number {
  writing-mode: vertical-lr;
  transform: rotate(180deg);
  margin: 5px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
#a-question-number #a-text {
  font-size: 18px;
  font-weight: bold;
  line-height: 1.22;
  letter-spacing: -0.36px;
  color: var(--color-1);
}
#a-question-number #a-number {
  margin: 13px 0;
  opacity: 0.3;
  font-family: Quicksand;
  font-size: 65px;
  font-weight: bold;
  line-height: 0.77;
  letter-spacing: -3.25px;
  color: var(--color-3);
}

#a-question-card {
  width: 100%;
  height: 377px;
  margin-bottom: 30px;
  padding: 50px;
  border-radius: 30px;
  background-color: var(--color-4);
}

#a-question-card p {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: -0.21px;
  color: var(--text-color);
}
.a-answer-option {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
}

.a-overlay-question-panel {
  position: absolute;
  bottom: 10px;
  right: 0;
}
.a-overlay-question-panel div {
  width: fit-content;
  padding: 10px 20px;
  align-self: flex-end;
  border-radius: 10px;

  box-shadow: 0 0 10px rgba(0, 0, 0, 0.16);
}
.a-overlay-question-panel div:hover {
  cursor: pointer;
}
.a-overlay-question-panel .a-saved {
  background: var(--color-2);
  color: white;
}
.a-overlay-question-panel .a-unsaved {
  background: white;
  color: var(--color-2);
}
.a-overlay-question-panel .a-saving {
  cursor: progress;
  opacity: 0.8;
}
</style>
